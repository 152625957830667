<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ faults.length }}) Work Errors</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="faults"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.error`]="{item}">
        <div class="d-flex flex-column" @click="getMoreInfo(item.id)" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.error }}</span>
          <small>{{ item.message }}</small>
        </div>
      </template>
      <template #[`item.amount`]="{item}">
        {{ item.amount }} F CFA
      </template>
      <template #[`item.get_date_str`]="{item}">
        {{ item.get_date_str }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Validated: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Error', value: 'error' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      faults: [],
      statusColor,
      search: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      this.getFaults();
      document.title = "TopUp | List of Faults"
  },

  methods:{

    getFaults(){
      axios
        .get('/api/v1/topup/get/my_faults/')
        .then(response => {
          this.faults = response.data  // get the data and fill into campaigns
          console.log(this.faults)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    }
  },
}
</script>
